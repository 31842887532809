import { GlobalStyles } from 'twin.macro'
import { Global } from '@emotion/react'
import { baseStyles } from './styles'

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Global styles={baseStyles} />
      <main id="main" tabIndex={-1}>
        {children}
      </main>
    </>
  )
}

export default Layout
