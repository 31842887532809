import { fabric } from 'fabric'
import { MAX_HEIGHT, MAX_WIDTH } from '../constant'
import axios from 'axios'
import Base64 from '../../../utils/Base64'

export const handleImageUpload = (e, drawCanvas, setOriginImage, setInpaintSnapShots) => {
  const file = e.target.files[0]
  if (!file) return
  const fileUrl = URL.createObjectURL(file)
  fabric.Image.fromURL(fileUrl, (img) => {
    const originWidth = img.width
    const originHeight = img.height

    let targetWidth = originWidth
    let targetHeight = originHeight

    if (originWidth > MAX_WIDTH || originHeight > MAX_HEIGHT) {
      if (originWidth / originHeight > MAX_WIDTH / MAX_HEIGHT) {
        img.scaleToWidth(MAX_WIDTH)
        targetWidth = MAX_WIDTH
        targetHeight = Math.ceil(img.height * img.scaleY)
      } else {
        img.scaleToHeight(MAX_HEIGHT)
        targetHeight = MAX_HEIGHT
        targetWidth = Math.ceil(img.width * img.scaleX)
      }
    }

    drawCanvas.setWidth(targetWidth)
    drawCanvas.setHeight(targetHeight)
    setOriginImage(img)
    setInpaintSnapShots([{ originImage: img }])
  })
}

const loadImageFromUrl = async (imageUrl) => {
  // Replace 'your_server_image_url' with the URL of the image you want to load from the server
  const serverImageUrl = imageUrl
  //'___BUDI_INVENTERA_BASE_URL___/Content/Images/2/01945734-723b-402f-8498-01e094f899ce/Image-8.jpg?v=94843562'

  try {
    // Load the image from the server
    const response = await axios.get(serverImageUrl, {
      responseType: 'arraybuffer',
    })

    // Convert the array buffer to a base64 string
    const base64Image = `data:image/jpeg;base64,${arrayBufferToBase64(response.data)}`
    return base64Image
  } catch (error) {
    console.error('Error loading image:', error)
  }
}

const arrayBufferToBase64 = (buffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return Base64.btoa(binary)
}

export const handleLoadImage = async (fileUrl, drawCanvas, setOriginImage, setInpaintSnapShots) => {
  // const file = e.target.files[0]
  if (!fileUrl) return
  // const fileUrl = URL.createObjectURL(file)
  const image = await loadImageFromUrl(fileUrl)
  fabric.Image.fromURL(image, (img) => {
    const originWidth = img.width
    const originHeight = img.height

    let targetWidth = originWidth
    let targetHeight = originHeight
    const maxWidth = MAX_WIDTH > window.innerWidth ? window.innerWidth : MAX_WIDTH
    const maxHeight = MAX_HEIGHT > window.innerHeight ? window.innerHeight : MAX_HEIGHT

    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        img.scaleToWidth(maxWidth)
        targetWidth = maxWidth
        targetHeight = Math.ceil(img.height * img.scaleY)
      } else {
        img.scaleToHeight(maxHeight)
        targetHeight = maxHeight
        targetWidth = Math.ceil(img.width * img.scaleX)
      }
    }

    drawCanvas.setWidth(targetWidth)
    drawCanvas.setHeight(targetHeight)
    setOriginImage(img)
    setInpaintSnapShots([{ originImage: img }])
  })
}

const dataURLToBlob = (dataurl) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const getMaskUrl = (drawCanvas) => {
  const prevBack = drawCanvas.backgroundImage
  drawCanvas.setBackgroundColor('rgb(0,0,0)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(new fabric.Image(''), drawCanvas.renderAll.bind(drawCanvas))
  const maskUrl = drawCanvas.toDataURL('image/jpeg')
  drawCanvas.setBackgroundColor('rgb(255,255,255)', drawCanvas.renderAll.bind(drawCanvas))
  drawCanvas.setBackgroundImage(prevBack, drawCanvas.renderAll.bind(drawCanvas))
  return maskUrl
}

export const handleImageDownload = (inpaintImage) => {
  const inpaintUrl = inpaintImage.toDataURL({ format: 'image/jpeg' })
  const e = new MouseEvent('click')
  let b = document.createElement('a')
  b.download = 'inpaint.jpg'
  b.href = inpaintUrl
  b.dispatchEvent(e)
}

export const getInpaintFormData = (drawCanvas, originImage) => {
  let param = new FormData()
  const maskUrl = getMaskUrl(drawCanvas)
  param.append('mask_file', dataURLToBlob(maskUrl), 'mask.jpg')
  const originUrl = originImage.toDataURL('image/jpeg')
  param.append('image_file', dataURLToBlob(originUrl), 'origin.jpg')
  return param
}

export const getDeblurFormData = (originImage) => {
  let param = new FormData()
  const originUrl = originImage.toDataURL('image/jpeg')
  param.append('origin', dataURLToBlob(originUrl), 'origin.jpg')
  return param
}

export const getReplaceImageFormData = (originImage, documentFolder, userId, imageName) => {
  let param = new FormData()
  const originUrl = originImage.toDataURL('image/jpeg')
  param.append('file', dataURLToBlob(originUrl), imageName)
  param.append('documentFolder', documentFolder)
  param.append('userId', userId)
  param.append('existingFileName', imageName)

  return param
}
