import { useCallback, useContext, useEffect, useMemo } from 'react'
import { fabric } from 'fabric'
import axios from 'axios'
import { faEraser, faSave, faEye, faReply, faDrawPolygon, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { Slider, message } from 'antd'
import { FabricContext } from './EditPage'
import { DRAW_TYPE, originSnapShot } from './constant'
import { getInpaintFormData, handleLoadImage, getReplaceImageFormData } from './fabricFunc/imageTransport'
import { viewReset } from './fabricFunc/zoomAndPan'
import { undoCommand, restoreSnapShot, canUndo, storeInpaintSnapShots } from './fabricFunc/fabricSnapShots'
import ToolButton from './ToolButton'
import { inpaintAPI } from './service'
import { useSearchParams } from 'react-router-dom'

const ToolBar = () => {
  const {
    drawCanvas,
    originImage,
    setOriginImage,
    drawType,
    setDrawType,
    penWidth,
    setPenWidth,
    setLassos,
    setActiveIndex,
    snapShots,
    setSnapShots,
    snapShotsID,
    setSnapShotsID,
    inpaintSnapShots,
    setInpaintSnapShots,
    inpaintSnapShotsID,
    setInpaintSnapShotsID,
    setIsLoading,
    // setShowOriginImage,
  } = useContext(FabricContext)
  const [searchParams] = useSearchParams()

  const imageUrl = searchParams.get('image')
  const documentFolder = searchParams.get('documentFolder')
  const userId = searchParams.get('userId')
  const imageName = searchParams.get('imageName')

  const clearCanvas = useCallback(() => {
    const fabricObjects = drawCanvas.current.getObjects()
    for (let i = 0; i < fabricObjects.length; i++) {
      drawCanvas.current.remove(fabricObjects[i])
    }
    setLassos([])
    setActiveIndex({ lassoIndex: -1, pointIndex: -1 })
    setDrawType(DRAW_TYPE.NORMAL)
    setSnapShots(originSnapShot)
    setSnapShotsID(0)
  }, [drawCanvas, setActiveIndex, setDrawType, setLassos, setSnapShots, setSnapShotsID])

  // const handleUpload = useCallback(
  //   (e) => {
  //     clearCanvas()
  //     setInpaintSnapShots(null)
  //     setInpaintSnapShotsID(0)
  //     handleImageUpload(e, drawCanvas.current, setOriginImage, setInpaintSnapShots)
  //   },
  //   [clearCanvas, drawCanvas, setInpaintSnapShots, setInpaintSnapShotsID, setOriginImage],
  // )

  // const handleDownload = useCallback(() => {
  //   handleImageDownload(originImage)
  // }, [originImage])

  const handleSave = useCallback(async () => {
    setIsLoading(true)
    const formData = getReplaceImageFormData(originImage, documentFolder, userId, imageName)
    const response = await axios.post(`___BUDI_INVENTERA_BASE_URL___/api/item/ReplaceFile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (response.data.status === 200) {
      message.success('success')
    } else {
      message.error('Failed')
    }
    setIsLoading(false)
  }, [originImage, documentFolder, userId, imageName])

  const handleInpaint = useCallback(() => {
    viewReset(drawCanvas.current)
    const formData = getInpaintFormData(drawCanvas.current, originImage)
    setIsLoading(true)
    axios
      .post(inpaintAPI, formData, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key':
            '83ee2700a51124ef1f405a7098ed47d2e9a289ae73411ded9393bcdef891362177634d02019807b0887d701e4d04fc39',
        },
      })
      .then((res) => {
        const inpaintImage = new Image()
        inpaintImage.crossOrigin = 'Anonymous'
        inpaintImage.src = URL.createObjectURL(res.data)
        console.log(inpaintImage.src)
        inpaintImage.onload = () => {
          const newImage = new fabric.Image(inpaintImage)
          clearCanvas()
          storeInpaintSnapShots(
            newImage,
            inpaintSnapShots,
            inpaintSnapShotsID,
            setInpaintSnapShots,
            setInpaintSnapShotsID,
          )
          setOriginImage(newImage)
          message.success('success')
          setIsLoading(false)
        }
      })
      .catch((err) => {
        message.error(err.message)
        setIsLoading(false)
      })
  }, [
    clearCanvas,
    drawCanvas,
    inpaintSnapShots,
    inpaintSnapShotsID,
    originImage,
    setInpaintSnapShots,
    setInpaintSnapShotsID,
    setIsLoading,
    setOriginImage,
  ])

  // const handleDeblur = useCallback(() => {
  //   viewReset(drawCanvas.current)
  //   const formData = getDeblurFormData(originImage)
  //   setIsLoading(true)
  //   axios
  //     .post(deblurAPI, formData, { responseType: 'blob' })
  //     .then((res) => {
  //       const inpaintImage = new Image()
  //       inpaintImage.crossOrigin = 'Anonymous'
  //       inpaintImage.src = URL.createObjectURL(res.data)
  //       inpaintImage.onload = () => {
  //         const newImage = new fabric.Image(inpaintImage)
  //         clearCanvas()
  //         storeInpaintSnapShots(
  //           newImage,
  //           inpaintSnapShots,
  //           inpaintSnapShotsID,
  //           setInpaintSnapShots,
  //           setInpaintSnapShotsID,
  //         )
  //         setOriginImage(newImage)
  //         message.success('success')
  //         setIsLoading(false)
  //       }
  //     })
  //     .catch((err) => {
  //       message.error(err.message)
  //       setIsLoading(false)
  //     })
  // }, [
  //   clearCanvas,
  //   drawCanvas,
  //   inpaintSnapShots,
  //   inpaintSnapShotsID,
  //   originImage,
  //   setInpaintSnapShots,
  //   setInpaintSnapShotsID,
  //   setIsLoading,
  //   setOriginImage,
  // ])

  const undoDisabled = useMemo(() => {
    return !canUndo(snapShotsID)
  }, [snapShotsID])

  const executeUndo = useCallback(() => {
    if (undoDisabled) return
    const curState = undoCommand(snapShots, snapShotsID, setSnapShotsID)
    restoreSnapShot(curState, drawCanvas.current, setLassos, setActiveIndex, setDrawType)
  }, [drawCanvas, setActiveIndex, setDrawType, setLassos, setSnapShotsID, snapShots, snapShotsID, undoDisabled])

  // const redoDisabled = useMemo(() => {
  //   return !canRedo(snapShots, snapShotsID)
  // }, [snapShots, snapShotsID])

  // const executeRedo = useCallback(() => {
  //   if (redoDisabled) return
  //   const curState = redoCommand(snapShots, snapShotsID, setSnapShotsID)
  //   restoreSnapShot(curState, drawCanvas.current, setLassos, setActiveIndex, setDrawType)
  // }, [drawCanvas, redoDisabled, setActiveIndex, setDrawType, setLassos, setSnapShotsID, snapShots, snapShotsID])

  // const backDisabled = useMemo(() => {
  //   return !canUndo(inpaintSnapShotsID)
  // }, [inpaintSnapShotsID])

  // const executeInpaintBack = useCallback(() => {
  //   if (backDisabled) return
  //   const curState = undoCommand(inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID)
  //   restoreInpaintSnapShot(curState, setOriginImage)
  // }, [backDisabled, inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID, setOriginImage])

  // const forwardDisabled = useMemo(() => {
  //   return !canRedo(inpaintSnapShots, inpaintSnapShotsID)
  // }, [inpaintSnapShots, inpaintSnapShotsID])

  // const executeInpaintForward = useCallback(() => {
  //   if (forwardDisabled) return
  //   const curState = redoCommand(inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID)
  //   restoreInpaintSnapShot(curState, setOriginImage)
  // }, [forwardDisabled, inpaintSnapShots, inpaintSnapShotsID, setInpaintSnapShotsID, setOriginImage])

  useEffect(() => {
    if (!(clearCanvas && drawCanvas && setInpaintSnapShots && setInpaintSnapShotsID && setOriginImage && imageUrl))
      return

    //const fileUrl = '___BUDI_WWW_BASE_URL___/Portals/0/auktion/lastbil152/7.jpg'
    const fileUrl = decodeURI(imageUrl)
    handleLoadImage(fileUrl, drawCanvas.current, setOriginImage, setInpaintSnapShots)
  }, [clearCanvas, drawCanvas, setInpaintSnapShots, setInpaintSnapShotsID, setOriginImage, imageUrl])

  return (
    <div className="flex justify-center">
      <div className="max-w-full">
        {/* Upload Image Box */}
        {/* <div className={`mb-16 ${originImage === null ? `block` : `hidden`}`}>
          <InputButton
            onChange={handleUpload}
            tailWindStyle={`hover:bg-gray-200 border-gray-300 border-2 rounded-xl border-dashed bg-gray-100`}
          >
            {useMemo(
              () => (
                <div className="flex flex-col space-y-24 mx-40 my-40">
                  <FileImageOutlined className="text-6xl" />
                  <div className="text-2xl">Upload an image from your file system</div>
                </div>
              ),
              [],
            )}
          </InputButton>
        </div> */}
        {/* Tool Bar */}
        <div
          className={`mt-2 px-4 py-1 border-gray-300 border-2 rounded-xl border-dashed max-w-full overflow-x-auto ${
            originImage === null ? `hidden` : `flex`
          }`}
        >
          <div className="flex">
            {/* <InputButton
              disabledPopConfirm={false}
              tailWindStyle={`hover:bg-gray-100 px-2 py-1 rounded-lg`}
              title="upload"
              onChange={handleUpload}
            >
              {useMemo(
                () => (
                  <FontAwesomeIcon icon={faUpload} />
                ),
                [],
              )}
            </InputButton> */}
            {/* <ToolButton onClick={handleDownload} icon={faDownload} title="download" /> */}
            <ToolButton onClick={handleSave} icon={faSave} title="save" />
          </div>

          <div className="flex">
            {/* <ToolButton disabledPopConfirm={false} onClick={clearCanvas} icon={faRotate} title="clear" /> */}

            <ToolButton onClick={executeUndo} icon={faReply} title="undo" disabled={undoDisabled} />

            {/* <ToolButton onClick={executeRedo} icon={faShare} title="redo" disabled={redoDisabled} /> */}
          </div>

          <div className="flex">
            <ToolButton
              onClick={useCallback(() => viewReset(drawCanvas.current), [drawCanvas])}
              icon={faEye}
              title="view reset"
            />

            <ToolButton
              isActive={drawType === DRAW_TYPE.FREE_DRAW}
              onClick={useCallback(
                () =>
                  drawType === DRAW_TYPE.FREE_DRAW ? setDrawType(DRAW_TYPE.NORMAL) : setDrawType(DRAW_TYPE.FREE_DRAW),
                [drawType, setDrawType],
              )}
              icon={faEraser}
              title="eraser"
            />

            <div style={{ width: 100 }}>
              <Slider
                tooltip={{ placement: 'bottom' }}
                defaultValue={10}
                max={30}
                min={5}
                value={penWidth}
                onChange={useCallback(
                  (v) => {
                    setPenWidth(v)
                  },
                  [setPenWidth],
                )}
              />
            </div>

            <ToolButton
              isActive={drawType === DRAW_TYPE.LASSO_DRAW}
              onClick={useCallback(() => {
                if (drawType === DRAW_TYPE.LASSO_DRAW) {
                  setDrawType(DRAW_TYPE.NORMAL)
                } else {
                  setDrawType(DRAW_TYPE.LASSO_DRAW)
                  setActiveIndex({ lassoIndex: -1, pointIndex: -1 })
                }
              }, [drawType, setActiveIndex, setDrawType])}
              icon={faDrawPolygon}
              title="lasso drawing"
            />

            {/* <ToolButton
              isActive={drawType === DRAW_TYPE.LASSO_DRAG_POINTS}
              onClick={useCallback(
                () =>
                  drawType === DRAW_TYPE.LASSO_DRAG_POINTS
                    ? setDrawType(DRAW_TYPE.NORMAL)
                    : setDrawType(DRAW_TYPE.LASSO_DRAG_POINTS),
                [drawType, setDrawType],
              )}
              icon={faHand}
              title="lasso dragging"
            /> */}
          </div>

          <div className="flex">
            {/* <ToolButton
              onClick={executeInpaintBack}
              icon={faArrowLeft}
              title="image back"
              disabled={backDisabled}
              disabledPopConfirm={false}
            />

            <ToolButton
              onClick={executeInpaintForward}
              icon={faArrowRight}
              title="image forward"
              disabled={forwardDisabled}
              disabledPopConfirm={false}
            />

            <ToolButton
              onMouseDown={useCallback(() => {
                setShowOriginImage(true)
              }, [setShowOriginImage])}
              onMouseUp={useCallback(() => {
                setShowOriginImage(false)
              }, [setShowOriginImage])}
              icon={faCodeCompare}
              title="image comparision"
              disabled={backDisabled}
            /> */}

            <ToolButton onClick={handleInpaint} icon={faWandMagicSparkles} title="inpaint" disabledPopConfirm={false} />

            {/* <ToolButton onClick={handleDeblur} icon={faGem} title="deblur" disabledPopConfirm={false} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolBar
